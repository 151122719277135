/* eslint-disable no-useless-escape */
export const loadLocalImage = (image: string, folder: string = "") => {
  return folder === ""
    ? require(`../assets/images/${image}`).default
    : require(`../assets/images/${folder}/${image}`).default;
};

export const getQueryUrlParam = (param: string) => {
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  return queryParams.get(param) || null;
};

export const extractDDD = (phone: string) => {
  if (phone) {
    const ddd = phone.match(/^[^\d]{0,5}?(\d{2})[^\d]{0,5}[\w\s-]+$/) ?? "";
    return ddd[1];
  } else return 0;
};

export const onlyPhoneNumber = (phone: string) => {
  if (phone) {
    const onlyNumber = phone.match(/\(\d{2}\)\s+(.+)/) ?? "";
    return onlyNumber.length > 1
      ? onlyNumber[1].replace("-", "")
      : onlyNumber[1];
  } else return 0;
};
